import React, { useState } from "react";
import { AuthForm } from "./AuthForm";
import { FormControl, FormLabel, Input, Link } from "@chakra-ui/react";
import { PasswordInput } from "./PasswordInput";
import { Script } from "gatsby";
import { useCallbackUrl } from "./utils";

type LoginFormProps = {
  onSignUp: () => void;
};

export function LoginForm({ onSignUp }: LoginFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const callbackUrl = useCallbackUrl();
  return (
    <>
      {/* load the recaptcha script so we can use it in the submit handler */}
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
      />
      <AuthForm
        buttonText="Log in with email"
        otherText="New to Apollo?"
        linkText="Sign up"
        onLinkClick={onSignUp}
        isLoading={loading}
        error={error}
        onSubmit={async (event) => {
          event.preventDefault();

          setLoading(true);

          const { email, password } = event.currentTarget;
          try {
            const recaptchaToken = await new Promise((resolve) => {
              window.grecaptcha.ready(async () => {
                const recaptchaToken = await window.grecaptcha.execute(
                  process.env.GATSBY_RECAPTCHA_SITE_KEY as string,
                  { action: "odyssey_login" }
                );
                resolve(recaptchaToken);
              });
            });

            const response = await fetch(
              `${process.env.GATSBY_API_URL}/login/apollo`,
              {
                credentials: "include",
                headers: {
                  "content-type": "application/json",
                  "sec-fetch-mode": "cors",
                },
                body: JSON.stringify({
                  email: email.value,
                  password: password.value,
                  recaptchaToken,
                }),
                method: "POST",
                mode: "cors",
              }
            );

            if (!response.ok) {
              const message = await response.text();
              throw new Error(message);
            }
          } catch (error) {
            setLoading(false);
            if (error instanceof Error) {
              setError(error);
            }
            return;
          }

          window.gtag?.("event", "login");
          location.href = callbackUrl;
        }}
      >
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input size="lg" type="email" name="email" placeholder="Email" />
        </FormControl>
        <div>
          <PasswordInput size="lg" mb="2" name="password" />
          <Link
            color="link"
            fontWeight="semibold"
            isExternal
            href="https://studio.apollographql.com/forgot-password"
          >
            Forgot password?
          </Link>
        </div>
      </AuthForm>
    </>
  );
}
