import React, { useState } from "react";
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";

export function PasswordInput({ children, ...props }: InputProps) {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <FormControl isRequired>
      <Flex align="flex-start">
        <FormLabel>Password</FormLabel>
        <Checkbox
          isRequired={false}
          colorScheme="indigo"
          isChecked={passwordShown}
          onChange={(event) => setPasswordShown(event.target.checked)}
          ml="auto"
        >
          Show password
        </Checkbox>
      </Flex>
      <Input type={passwordShown ? "text" : "password"} {...props} />
      {children}
    </FormControl>
  );
}
