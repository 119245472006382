import React, { useState } from "react";
import { AuthForm } from "./AuthForm";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { PasswordInput } from "./PasswordInput";
import { useSignUpMutation } from "@graphql";

type SignUpFormProps = {
  onLogIn: () => void;
};

export function SignUpForm({ onLogIn }: SignUpFormProps) {
  const [password, setPassword] = useState("");
  const [signUp, { data, loading, error }] = useSignUpMutation({
    onCompleted: () => window.gtag?.("event", "sign_up"),
  });

  if (data) {
    return (
      <Text fontSize="lg">
        Almost done! 🎉 We just sent an email to{" "}
        <strong>{data.signUp?.email}</strong> for you to verify your identity.
      </Text>
    );
  }

  return (
    <AuthForm
      buttonText="Create Apollo account"
      otherText="Already have an Apollo account?"
      linkText="Log in"
      onLinkClick={onLogIn}
      isLoading={loading}
      error={error}
      onSubmit={(event) => {
        event.preventDefault();

        const { email, fullName } = event.currentTarget;
        signUp({
          variables: {
            email: email.value,
            fullName: fullName.value,
            password,
          },
        });
      }}
    >
      <FormControl isRequired>
        <FormLabel>Full name</FormLabel>
        <Input
          size="lg"
          name="fullName"
          placeholder='Example: "Alan Shepard"'
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          size="lg"
          placeholder="Example: alan.shepard@gmail.com"
          type="email"
          name="email"
        />
      </FormControl>
      <PasswordInput
        minLength={8}
        onChange={(event) => setPassword(event.target.value)}
      >
        <FormHelperText display="flex" alignItems="center">
          {password.length > 0 && (
            <Box
              mr="1"
              color={password.length < 8 ? "failure" : "success"}
              as={password.length < 8 ? FiXCircle : FiCheckCircle}
            />
          )}
          Make sure your password is at least 8 characters long.
        </FormHelperText>
      </PasswordInput>
    </AuthForm>
  );
}
