import React from "react";
import ReactMarkdown, { Options } from "react-markdown";
import { CustomLink } from "./CustomLink";
import { InlineCode } from "./InlineCode";
import { LinkProps } from "@chakra-ui/react";
import { MarkdownCodeBlock } from "@apollo/chakra-helpers";

export const MarkdownRenderer = (props: Options) => {
  return (
    <ReactMarkdown
      components={{
        code: InlineCode,
        pre: (props) => <MarkdownCodeBlock {...props} disableCopy />,
        a: (props: LinkProps) => <CustomLink color="link" {...props} />,
      }}
      unwrapDisallowed
      {...props}
    />
  );
};
