import React, { PropsWithChildren } from "react";
import { Box, StackProps, useColorModeValue } from "@chakra-ui/react";
import { MarkdownRenderer } from "./MarkdownRenderer";

type CalloutProps = PropsWithChildren<StackProps> & {
  children: string;
};

export const Callout = ({ children }: CalloutProps) => {
  const borderColor = useColorModeValue("indigo.400", "indigo.100");
  return (
    <Box
      borderRadius="4"
      px="4"
      py="2"
      border="2px solid"
      borderColor={borderColor}
    >
      <MarkdownRenderer>{children}</MarkdownRenderer>
    </Box>
  );
};
