import React from "react";
import { AuthButton } from "./AuthButton";
import {
  Box,
  Divider,
  Grid,
  Heading,
  Link,
  SimpleGrid,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { FiGithub, FiLock } from "react-icons/fi";
import { IS_PROD } from "@utils";
import { LoginForm } from "./LoginForm";
import { SignUpForm } from "./SignUpForm";
import { TinyColor } from "@ctrl/tinycolor";
import { colors } from "@apollo/space-kit/colors";

type MultiFormProps = {
  isSignUp: boolean;
  onChange: (isSignUp: boolean) => void;
};

export function MultiForm({ isSignUp, onChange }: MultiFormProps) {
  const { colorMode } = useColorMode();
  const action = isSignUp ? "Sign up" : "Log in";

  const localLoginFlow = () => {
    window.addEventListener("focus", () => window.location.reload());
  };

  return (
    <Box py="5" px="6">
      <Heading size="lg">{isSignUp ? "Create an account" : "Log in"}</Heading>
      {!IS_PROD && (
        <Box
          p="2"
          my="2"
          bg="red.800"
          rounded="md"
          border="solid 4px"
          borderColor="red.500"
        >
          <Text>
            For security, localhost and Netlify deploy previews will not
            auto-redirect back after login. You can{" "}
            <Link
              onClick={localLoginFlow}
              as="a"
              target="_blank"
              href={`${process.env.GATSBY_STUDIO_URL}/login?type=sso`}
              color="link"
              fontWeight="semibold"
            >
              click here
            </Link>{" "}
            to open the login flow in a new tab. The page will auto-refresh when
            you return.
          </Text>
        </Box>
      )}
      {isSignUp && (
        <Text mt="3">
          You&apos;ll have access to both Odyssey and Apollo Studio. We&apos;ll
          also keep track of your progress and achievements!
        </Text>
      )}
      <SimpleGrid mt="6" spacing="3" columns={{ md: 2 }}>
        <AuthButton
          provider="github"
          leftIcon={<FiGithub />}
          css={() => {
            if (colorMode === "light") {
              const { base } = colors.black;
              return {
                backgroundColor: base,
                color: "white",
                "&:hover": {
                  backgroundColor: new TinyColor(base)
                    .setAlpha(0.8)
                    .toRgbString(),
                },
              };
            }
          }}
        >
          {action} with GitHub
        </AuthButton>
        <AuthButton variant="outline" leftIcon={<FiLock />}>
          {action} with SSO
        </AuthButton>
      </SimpleGrid>
      <Grid
        gap="2"
        alignItems="center"
        templateColumns="1fr auto 1fr"
        pos="relative"
        textTransform="uppercase"
        color="inactive"
        mt="6"
        mb="2"
      >
        <Divider />
        <span>or</span>
        <Divider />
      </Grid>
      {isSignUp ? (
        <SignUpForm onLogIn={() => onChange(false)} />
      ) : (
        <LoginForm onSignUp={() => onChange(true)} />
      )}
      <Box mt="6" color="inactive" fontSize="sm" textAlign="center">
        This site is protected by reCAPTCHA.
        <br />
        Google&apos;s{" "}
        <Link color="link" href="https://policies.google.com/privacy">
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link color="link" href="https://policies.google.com/terms">
          Terms of Service
        </Link>{" "}
        apply.
      </Box>
    </Box>
  );
}
