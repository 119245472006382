import React from "react";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  useAcceptPrivacyPolicyMutation,
  useGetPrivacyPolicyAcceptanceQuery,
} from "@graphql";
import { useCallbackUrl } from "./MultiForm";

export const PrivacyPolicyCheck = () => {
  const { data } = useGetPrivacyPolicyAcceptanceQuery();
  const [acceptPrivacyPolicy, { loading }] = useAcceptPrivacyPolicyMutation({
    onCompleted() {
      window.location.reload();
    },
  });
  const callbackUrl = useCallbackUrl();

  if (
    data?.me &&
    data.me.__typename === "User" &&
    !data.me.acceptedPrivacyPolicyAt
  ) {
    return (
      <Modal size="sm" isOpen onClose={() => undefined}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Privacy Policy Notice</ModalHeader>
          <ModalBody as={Stack} spacing="4">
            <Text>
              We noticed you haven&apos;t accepted our privacy policy and terms
              of service yet. In order to continue using Odyssey you must accept
              these terms.
            </Text>
            <Text>
              By clicking the button below, you agree to Apollo&apos;s{" "}
              <Link
                color="link"
                href="https://www.apollographql.com/Apollo-Terms-of-Service.pdf"
              >
                terms of service
              </Link>{" "}
              and{" "}
              <Link
                color="link"
                href="https://www.apollographql.com/Apollo-Privacy-Policy.pdf"
              >
                privacy policy
              </Link>
              .
            </Text>
          </ModalBody>
          <ModalFooter as={Stack}>
            <Button
              colorScheme="indigo"
              isLoading={loading}
              onClick={() => acceptPrivacyPolicy()}
              w="full"
            >
              I Agree
            </Button>
            <Button
              variant="ghost"
              w="full"
              size="sm"
              as="a"
              href={`${process.env.GATSBY_API_URL}/logout?cb=${callbackUrl}`}
            >
              Log out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return null;
};
