import { createContext, useContext } from "react";
import { useLocation } from "@reach/router";

/**
 * The value passed into this context must be a function that returns the
 * desired callback URL for login/signup forms and buttons.
 */
export const CallbackUrlContext = createContext({ query: "" });

export function useCallbackUrl() {
  const location = useLocation();
  const callbackUrl = useContext(CallbackUrlContext);
  return location.origin + location.pathname + callbackUrl.query;
}
