import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Callout } from "../components/Callout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ConditionalText = makeShortcode("ConditionalText");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ConditionalText language="typescript" mdxType="ConditionalText">
      <Callout mdxType="Callout">
  The video currently shows the JavaScript version of the course. For the
  TypeScript version, check the contents below.
      </Callout>
    </ConditionalText>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      