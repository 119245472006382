import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CollapsibleSection = makeShortcode("CollapsibleSection");
const Cloudinary = makeShortcode("Cloudinary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: Depending on when you created your account and what plan you're on, your Studio account might look a bit different than what we're showing in the video or lesson instructions.`}</p>
      <br />
      <p parentName="blockquote">{`Before continuing with the lesson, expand the section below to find your specific
steps on how to create a graph.`}</p>
    </blockquote>
    <br />
    <CollapsibleSection title="Expand to see your graph creation steps" colorScheme="yellow" icon={FiAlertTriangle} mdxType="CollapsibleSection">
      <p>{`First things first, do you have an GraphOS account?`}</p>
      <CollapsibleSection title="I don't have a GraphOS Studio account!" mdxType="CollapsibleSection">
        <p>{`First, you'll need to create an account. Visit the following link: `}<a parentName="p" {...{
            "href": "http://studio.apollographql.com/signup?referrer=odyssey"
          }}>{`http://studio.apollographql.com/signup`}</a>{`.`}</p>
        <p>{`After creating your account, you'll see something that looks like this:`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/net-new-user_idog5n.png" alt="The Studio homepage showing the Graphs tab and a 'Welcome to GraphOS Studio message'" mdxType="Cloudinary" />
        <p>{`Click the `}<em parentName="p">{`"Coming from Apollo Odyssey..."`}</em>{` link.`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/click-coming-from-odyssey_zvebmh.png" alt="The Studio homepage showing the Graphs tab and a 'Welcome to GraphOS Studio message', with an arrow pointing to the text link saying 'Coming from Apollo Odyssey...'" mdxType="Cloudinary" />
        <p>{`You'll see a modal pop up to create a classic graph.`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/new-classic-graph-modal_agofol.png" alt="The New Classic Graph Creation modal" mdxType="Cloudinary" />
        <p>{`Then, `}<strong parentName="p">{`skip the next two questions`}</strong>{`. Jump back into this lesson's instructions and follow along.`}</p>
      </CollapsibleSection>
      <br />
      <p>{`If you do have an account already and you're logged in to Studio, what do you see
on the top right of the homepage's navigation bar?`}</p>
      <Cloudinary src="v1678215931/odyssey/alternate-graph-creation/check-main-nav_tsfen8.png" alt="The Studio homepage's navigation bar" mdxType="Cloudinary" />
      <CollapsibleSection title="1A) I see a 'Convert to Serverless (Free)' button." mdxType="CollapsibleSection">
        <p>{`You'll need to upgrade your plan to the serverless (free) plan.`}</p>
        <p>{`Click `}<em parentName="p">{`"Convert to Serverless (Free)"`}</em>{` on the top right.`}</p>
        <Cloudinary src="v1678215974/odyssey/alternate-graph-creation/upgrade-step1_yguwal.png" alt="The Studio homepage's navigation bar, with the 'Convert to Serverless (Free)' button highlighted" mdxType="Cloudinary" />
        <p>{`A modal will pop up explaining the plan's features.`}</p>
        <Cloudinary src="v1678215995/odyssey/alternate-graph-creation/upgrade-step2_nfwnsu.png" alt="A modal explaining the serverless (free) plan features" mdxType="Cloudinary" />
        <p>{`Click `}<em parentName="p">{`"Yes, convert my account"`}</em>{`.`}</p>
        <p>{`Next, `}<strong parentName="p">{`what do you see on the Studio homepage`}</strong>{`? Expand another collapsible section below that best matches what you see.`}</p>
      </CollapsibleSection>
      <CollapsibleSection title="1B) I don't see a 'Convert to Serverless (Free)' button." mdxType="CollapsibleSection">
        <p>{`Click `}<em parentName="p">{`"Create New Graph"`}</em>{`.`}</p>
        <Cloudinary src="v1678216272/odyssey/alternate-graph-creation/no-upgrade-button_1_lttumr.png" alt="The Studio homepage with the 'New Graph' button highlighted." mdxType="Cloudinary" />
        <p>{`You'll see a modal pop up to create a graph.`}</p>
        <Cloudinary src="v1678216210/odyssey/alternate-graph-creation/new-graph-modal_vndeac.png" alt="A Graph Creation modal" mdxType="Cloudinary" />
        <p>{`Skip the next question, jump back into this lesson's instructions and follow along.`}</p>
      </CollapsibleSection>
      <br />
      <p>{`If you chose 1A) and finished converting your account, what do you see on the Studio homepage?`}</p>
      <CollapsibleSection title="2A) The page shows a 'Welcome to GraphOS Studio' screen." mdxType="CollapsibleSection">
        <p>{`Your page looks like this:`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/net-new-user_idog5n.png" alt="The Studio homepage showing the Graphs tab and a 'Welcome to GraphOS Studio message'" mdxType="Cloudinary" />
        <p>{`Click the `}<em parentName="p">{`"Coming from Apollo Odyssey..."`}</em>{` link.`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/click-coming-from-odyssey_zvebmh.png" alt="The Studio homepage showing the graphs tab and a 'Welcome to GraphOS Studio message', with an arrow pointing to the text link saying 'Coming from Apollo Odyssey...'" mdxType="Cloudinary" />
        <p>{`You'll see a modal pop up to create a classic graph.`}</p>
        <Cloudinary src="v1678215810/odyssey/alternate-graph-creation/new-classic-graph-modal_agofol.png" alt="The New Classic Graph Creation modal" mdxType="Cloudinary" />
        <p>{`Then, jump back into this lesson's instructions and follow along.`}</p>
      </CollapsibleSection>
      <CollapsibleSection title="2B) I see a 'Create New Graph' button with NO dropdown arrow beside it." mdxType="CollapsibleSection">
        <p>{`Your page looks like this:`}</p>
        <Cloudinary src="v1678216404/odyssey/alternate-graph-creation/existing-supergraphs-no-classic-graphs_hsexbd.png" alt="The Studio homepage on the Graphs tab with a 'Create New Graph' button with no dropdown arrow beside it" mdxType="Cloudinary" />
        <p>{`Scroll down to find a notice on the bottom right corner of the screen. Click `}<em parentName="p">{`"Head over to our classic graph flow"`}</em>{`.`}</p>
        <Cloudinary src="v1678216405/odyssey/alternate-graph-creation/click-notice-bottomright_rob6ll.png" alt="The Studio homepage under the Graphs tab, a notice on the bottom right corner of the screen is highlighted." mdxType="Cloudinary" />
        <p>{`You'll see a modal pop up to create a classic graph.`}</p>
        <Cloudinary src="v1678216406/odyssey/alternate-graph-creation/new-classic-graph-modal_rtygox.png" alt="The New Classic Graph Creation modal" mdxType="Cloudinary" />
        <p>{`Jump back into this lesson's instructions and follow along.`}</p>
      </CollapsibleSection>
      <CollapsibleSection title="2C) I see a 'Create New Graph' button WITH a dropdown arrow beside it." mdxType="CollapsibleSection">
        <p>{`Your page looks like this:`}</p>
        <Cloudinary src="v1678216521/odyssey/alternate-graph-creation/existing-classic-graphs_oupjv7.png" alt="The Studio homepage on the Graphs tab with a 'Create New Graph' button with no dropdown arrow beside it" mdxType="Cloudinary" />
        <p>{`Click the arrow beside `}<em parentName="p">{`"Create New Graph"`}</em>{`, then click `}<em parentName="p">{`"New Classic Graph"`}</em>{`.`}</p>
        <Cloudinary src="v1678216520/odyssey/alternate-graph-creation/classic-graph-create-step1_jzmpxt.png" alt="The Studio homepage clicking the arrow and 'New Classic Graph' selected" mdxType="Cloudinary" />
        <p>{`You'll see a modal pop up to create a classic graph.`}</p>
        <Cloudinary src="v1678216520/odyssey/alternate-graph-creation/classic-graph-create-step2_lcbwpi.png" alt="The New Classic Graph Creation modal" mdxType="Cloudinary" />
        <p>{`Jump back into this lesson's instructions and follow along.`}</p>
      </CollapsibleSection>
    </CollapsibleSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      