import React from "react";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";
import { Link, LinkProps } from "@chakra-ui/react";

export function CustomLink({ href, ...props }: LinkProps) {
  const { site } = useStaticQuery<Queries.GetPathPrefixQuery>(
    graphql`
      query GetPathPrefix {
        site {
          pathPrefix
        }
      }
    `
  );

  const linkProps = href?.startsWith("/")
    ? {
        as: GatsbyLink,
        to: href.replace(new RegExp(`^${site?.pathPrefix}`), ""),
      }
    : {
        href,
        isExternal: !href?.startsWith("#"),
      };
  return <Link {...linkProps} {...props} />;
}
