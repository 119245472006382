import React, { ReactNode } from "react";
import { Code, useColorModeValue } from "@chakra-ui/react";

type InlineCodeProps = {
  children: ReactNode;
};

export function InlineCode({ children }: InlineCodeProps) {
  const bgColor = useColorModeValue("blue.100", "black.200");
  const textColor = useColorModeValue("blue.500", "blue.200");
  return (
    <Code bgColor={bgColor} color={textColor} fontSize="calc(1em / 1.125)">
      {children}
    </Code>
  );
}
