import { colors, typography } from "@apollo/brand";
import { components } from "@apollo/chakra-helpers";
import { extendTheme } from "@chakra-ui/react";

const {
  gray,
  navy: indigo,
  blue,
  yellow,
  orange,
  red,
  green,
  purple,
  silver,
  black,
  beige,
} = colors.primitives;

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  semanticTokens: {
    colors: {
      bg: {
        default: "silver.100",
        _dark: "black.100",
      },
      bgSecondary: {
        default: "silver.300",
        _dark: "black.200",
      },
      primary: {
        default: "blue.300",
        _dark: "blue.300",
      },
      success: {
        default: "green.400",
        _dark: "green.300",
      },
      error: {
        default: "red.600",
        _dark: "red.200",
      },
      failure: {
        default: "red.600",
        _dark: "red.200",
      },
      link: {
        default: "blue.300",
        _dark: "blue.200",
      },
      inactive: {
        default: "gray.500",
        _dark: "gray.400",
      },
    },
  },
  textStyles: {
    ...typography.tokens,

    smallCaps: {
      fontFamily: "mono",
      textTransform: "uppercase",
      fontWeight: "semibold",
      lineHeight: "none",
    },
  },
  styles: {
    global: {
      ".grecaptcha-badge": { visibility: "hidden" },
      body: {
        bg: "bg",
      },
      h1: {
        letterSpacing: ".1rem",
      },
      h2: {
        letterSpacing: ".05rem",
      },
      h3: {
        letterSpacing: ".05rem",
      },
      h4: {
        letterSpacing: ".05rem",
      },
      p: {
        lineHeight: "1.6rem",
      },
    },
  },
  components: {
    ...components,
    Text: {
      baseStyle: {
        lineHeight: "1.5rem",
        fontSize: "md",
      },
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.8rem",
    md: "0.99rem",
    lg: "1.0rem",
    xl: "1.1rem",
    "2xl": "1.25rem",
    "3xl": "1.7rem",
    "4xl": "2.1rem",
    "5xl": "2.8rem",
    "6xl": "4rem",
  },
  fonts: {
    heading: "aeonik, 'aeonik', sans-serif",
    body: "Inter, 'Inter var', sans-serif",
    mono: "Fira Code, 'Fira Code Retina', monospace",
  },
  colors: {
    silver,
    gray,
    indigo,
    blue,
    yellow,
    orange,
    purple,
    red,
    green,
    black,
    beige,
  },
});

export default theme;
