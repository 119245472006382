import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CollapsibleSection = makeShortcode("CollapsibleSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <CollapsibleSection title="About Odyssey side quests" type="info" mdxType="CollapsibleSection">
      <p>{`Side quests are shorter courses that briefly introduce a single topic. By completing side quests, you can quickly add new GraphQL techniques to your developer toolbelt, helping you become the best graph developer you can be!`}</p>
      <p>{`Unlike Odyssey courses, side quests are more of a `}<strong parentName="p">{`case study`}</strong>{`, showing examples of how certain topics apply in a particular codebase. A repo with the demo app is included, in case you want to dig into the code more on your own.`}</p>
    </CollapsibleSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      