import React, { HTMLProps, ReactNode } from "react";
import { Box, Button, Link, Stack, Text } from "@chakra-ui/react";

interface AuthFormProps extends HTMLProps<HTMLFormElement> {
  buttonText: string;
  linkText: string;
  otherText: string;
  children: ReactNode;
  onLinkClick: () => void;
  isLoading: boolean;
  error?: Error;
}

export function AuthForm({
  children,
  buttonText,
  linkText,
  otherText,
  onLinkClick,
  isLoading,
  error,
  ...props
}: AuthFormProps) {
  return (
    <form {...props}>
      {error && (
        <Text mb="2" color="failure">
          {error.message}
        </Text>
      )}
      <Stack spacing="8">
        {children}
        <div>
          <Button
            isLoading={isLoading}
            type="submit"
            isFullWidth
            colorScheme="indigo"
            size="lg"
          >
            {buttonText}
          </Button>
          <Box textAlign="center" mt="3">
            {otherText}{" "}
            <Link
              color="link"
              as="button"
              type="button"
              fontWeight="semibold"
              onClick={onLinkClick}
            >
              {linkText}
            </Link>
            .
          </Box>
        </div>
      </Stack>
    </form>
  );
}
