import "@fontsource/fira-code/400.css";
import "@fontsource/fira-code/600.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import React from "react";
import { GatsbyBrowser } from "gatsby";
import { PrivacyPolicyCheck } from "@components/PrivacyPolicyCheck";

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps,
}) => !routerProps.location.state?.disableScrollUpdate;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      <PrivacyPolicyCheck />
      {element}
    </>
  );
};
