import React, { useState } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { useCallbackUrl } from "./utils";

interface AuthButtonProps extends ButtonProps {
  provider?: string;
}

export function AuthButton({ provider, ...props }: AuthButtonProps) {
  const callbackUrl = useCallbackUrl();
  const [loading, setLoading] = useState(false);

  let href;

  if (provider === "github") {
    href = `${process.env.GATSBY_API_URL}/login/github?cb=${callbackUrl}`;
  } else {
    href = `${process.env.GATSBY_STUDIO_URL}/login?type=sso&from=${callbackUrl}`;
  }

  return (
    <Button
      size="lg"
      as="a"
      href={href}
      isLoading={loading}
      onClick={() => setLoading(true)}
      {...props}
    />
  );
}
