exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-browse-tsx": () => import("./../../../src/pages/browse.tsx" /* webpackChunkName: "component---src-pages-browse-tsx" */),
  "component---src-pages-certifications-[id]-tsx": () => import("./../../../src/pages/certifications/[id].tsx" /* webpackChunkName: "component---src-pages-certifications-[id]-tsx" */),
  "component---src-pages-certifications-certification-slug-index-tsx": () => import("./../../../src/pages/certifications/{Certification.slug}/index.tsx" /* webpackChunkName: "component---src-pages-certifications-certification-slug-index-tsx" */),
  "component---src-pages-certifications-certification-slug-learning-path-tsx": () => import("./../../../src/pages/certifications/{Certification.slug}/learning-path.tsx" /* webpackChunkName: "component---src-pages-certifications-certification-slug-learning-path-tsx" */),
  "component---src-pages-dashboard-certifications-tsx": () => import("./../../../src/pages/dashboard/certifications.tsx" /* webpackChunkName: "component---src-pages-dashboard-certifications-tsx" */),
  "component---src-pages-dashboard-courses-tsx": () => import("./../../../src/pages/dashboard/courses.tsx" /* webpackChunkName: "component---src-pages-dashboard-courses-tsx" */),
  "component---src-pages-exams-exam-slug-[id]-tsx": () => import("./../../../src/pages/exams/{Exam.slug}/[id].tsx" /* webpackChunkName: "component---src-pages-exams-exam-slug-[id]-tsx" */),
  "component---src-pages-exams-exam-slug-index-tsx": () => import("./../../../src/pages/exams/{Exam.slug}/index.tsx" /* webpackChunkName: "component---src-pages-exams-exam-slug-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-lesson-tsx": () => import("./../../../src/templates/lesson.tsx" /* webpackChunkName: "component---src-templates-lesson-tsx" */)
}

