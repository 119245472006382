import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Callout } from "../components/Callout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Callout mdxType="Callout">
  Don't miss GraphQL Summit Virtual on November 7. [**Register for
  free!**](https://summit.graphql.com/virtual?utm_campaign=2024-10-03_graphqlsummitpromo&utm_medium=website&utm_source=odyssey)
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      